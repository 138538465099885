import React from 'react';
import logo from './42.svg';
import './App.css';
import logoCat from './cat.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <a
            className="App-link"
            href="https://github.com/salutkodo"
          >
          Github
          </a>
          <img src={logo} className="App-logo" alt="logo" />
          <a style={{display: 'flex', alignSelf: 'flex-end', flexShrink: 0}} href="https://soundcloud.com/elanor99/worldends">
            <img alt="" src={logoCat} style={{opacity: '0.5%', borderRadius: "5%", width: 100}}/>
          </a>
      </header>
    </div>
  );
}

export default App;
